var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.SkinComponent, _vm._b({
    tag: "component",
    on: {
      "click": _vm.click,
      "page": function (value) {
        return _vm.onPage(value);
      }
    },
    scopedSlots: _vm._u([{
      key: "write-button",
      fn: function () {
        return [_vm.scope.find(function (scope) {
          return _vm.writeScope.includes(scope);
        }) ? _c('v-btn', {
          attrs: {
            "x-large": "",
            "color": "primary"
          },
          on: {
            "click": _vm.write
          }
        }, [_vm._v("글쓰기")]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "pagination",
      fn: function () {
        return [_c('pagination-component', {
          attrs: {
            "value": _vm.page,
            "count": Math.ceil(_vm.summary.totalCount / _vm.limit)
          },
          on: {
            "input": function (value) {
              return _vm.onPage(value);
            }
          }
        })];
      },
      proxy: true
    }])
  }, 'component', Object.assign({}, _vm.$attrs, {
    code: _vm.code,
    notices: _vm.notices,
    summary: _vm.summary,
    boards: _vm.boards,
    page: _vm.page,
    limit: _vm.limit,
    hideWriteButton: _vm.hideWriteButton
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }