var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pagination"
  }, [_c('a', {
    staticClass: "pagination__backward",
    on: {
      "click": function ($event) {
        return _vm.input(1);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-double-left")])], 1), _c('a', {
    staticClass: "pagination__prev",
    on: {
      "click": function ($event) {
        return _vm.input(_vm.page - 1);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _vm._l([-2, -1, 0, 1, 2], function (i) {
    return [_vm.visibility(_vm.page + i) ? _c('div', {
      key: i
    }, [i == 0 ? _c('a', {
      staticClass: "pagination__current"
    }, [_vm._v(_vm._s(_vm.page))]) : _c('a', {
      on: {
        "click": function ($event) {
          return _vm.input(i + _vm.page);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.page + i))])]) : _vm._e()];
  }), _c('a', {
    staticClass: "pagination__next",
    on: {
      "click": function ($event) {
        return _vm.input(_vm.page + 1);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1), _c('a', {
    staticClass: "pagination__forward",
    on: {
      "click": function ($event) {
        return _vm.input(_vm.count);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-double-right")])], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }